body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'WixMadeforText';
  src: url('./fonts/WixMadeforText-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Inter Fonts */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* Repeat for other Inter font styles and weights */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_18pt-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* Repeat for other sizes (24pt, 28pt) similarly */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter_24pt-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* Continue for all other Inter font styles and weights */

/* Poppins Fonts */
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* Repeat for other Poppins font styles and weights */
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Roboto Fonts */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* Repeat for other Roboto font styles and weights */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
